import { Component,OnInit } from '@angular/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { TypeFlux } from 'src/app/domain/connecteur/type-flux';
import { environment } from 'src/environments/environment';
import { ImportFormatListItemComponent } from './import-format-list-item.component';

@Component({
	templateUrl: './import-format-list.component.html'
})
export class ImportFormatListComponent implements OnInit {
	/** Liste des fournisseurs **/
	liste: ListView<any,ImportFormatListItemComponent>;

	/** Type de flux **/
	typeFlux: TypeFlux;

	/** Fournisseur sélectionné **/
	selectedFournisseur: any;

	/** Connecteur sélectionné **/
	selectedConnecteur: any = null;

	/** Sélection d'un connecteur **/
	onConnecteurSelected: (connecteur: any) => void;

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,ImportFormatListItemComponent>({
			uri: `${environment.baseUrl}/controller/Connecteur/filtreConnecteursForImport/${this.typeFlux}/${this.selectedFournisseur.idFournisseur}`,
			component: ImportFormatListItemComponent,
			isLocal: true,
			extraOptions: {
				onConnecteurSelected: this.onConnecteurSelected
			},
			listeFilters: [{
				clef: 'type.fournisseurInterface',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseurInterface'
				},
				isDefault: true
			}],
			listeStaticFilters: [{
				clef: 'type.fournisseurInterface.isActif',
				type: TypeFilter.BOOLEAN,
				valeur: true
			}]
		});
	}
}